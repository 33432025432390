<template>
  <v-container
    fill-height
    class="pa-0 ma-0 code-container cream_light"
    style="max-width: none"
  >
    <v-row justify="center" align-content="center">
      <v-col cols="9" sm="8" md="7" lg="6" xl="4" class="text-center">
        <v-row justify="center" align="center" class="introduction py-5">
          <h2 class="my-3 my-sm-10">¡Bienvenido a la Academia!</h2>
          <div class="my-3 my-sm-10">
            <p>
              Aquí se encuentran las formaciones gratuítas de las que disfrutan
              los suscriptores de mi newsletter, además de mis programas de
              pago.
            </p>
            <p>
              Si quieres acceder, acepta la política de privacidad para
              suscribirte a la newsletter y entra.
            </p>
          </div>
        </v-row>
        <v-row class="py-5" justify="center">
          <v-form v-model="valid">
            <v-checkbox v-model="checkbox" color="brown">
              <template v-slot:label>
                <div class="checkout-text">
                  He leido y acepto la
                  <a
                    target="_blank"
                    href="https://unperroenlaciudad.es/politica-de-privacidad"
                    rel="noopener noreferrer"
                    @click.stop
                  >
                    política de privacidad
                  </a>
                </div>
              </template>
            </v-checkbox>
            <v-btn
              class="form-button"
              @click="updateUserInformation"
              :disabled="!valid || !checkbox"
              :loading="loading"
              color="red"
            >
              Entrar
            </v-btn>
          </v-form>
        </v-row>
        <v-row class="py-5 footer">
          <p style="font-size: 10px; text-align: left">
            Y para cumplir con el RGPD (Reglamento General de Protección de
            Datos), te aviso de que yo, María Lozano, soy la
            <b>responsable</b> de los datos que me proporcionas con la
            <b>finalidad</b> de que te envíe emails con información sobre
            educación canina y los servicios que ofrezco. Para entender que tus
            datos están seguros, debes de leer la política de privacidad, donde
            tienes todos tus <b>derechos</b> detallados. La
            <b>legitimización</b> para el tratamiento de tus datos y poder
            enviarte emails es tu consentimiento al aceptar la política de
            privacidad y el <b>destinatario</b> de tus datos es ActiveCampaign,
            la plataforma que gestiona el email marketing.
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { requiredRule } from "@/model/validationRules";
import { userActions } from "@/store/user";

export default {
  name: "Welcome",
  data() {
    return {
      loading: false,
      requiredRule: requiredRule,
      valid: false,
      user: {
        userName: undefined,
      },
      checkbox: false,
    };
  },
  methods: {
    async updateUserInformation() {
      this.loading = true;
      try {
        await this.$store.dispatch(userActions.create, this.user);
        await this.$router.push({
          path: "/app/home/",
          query: this.$route.query,
        });
      } catch (e) {
        this.$snackbar.error(
          "Error al contactar con el servidor. Pruebe otra vez en unos minutos"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass">
.introduction
  p
    color: var(--v-black-base)
    font-family: 'Gupter', serif
    font-size: calc(11px + 1vh)

  h2
    color: var(--v-black-base)
    font-size: calc(19px + 1.5vw)
    font-family: 'Josefin Sans', sans-serif
    text-transform: uppercase
    line-height: 1.4em

.checkout-text
  color: var(--v-brown-base)
  font-family: 'Gupter', serif

  a
    color: var(--v-red-base)

.footer
  color: var(--v-brown-base)
  font-family: 'Gupter', serif

.form-button
  border-radius: 0
  padding: 0 20px !important

  .v-btn__content
    color: white
    font-weight: 300
    font-family: "Josefin Sans", sans-serif
</style>
